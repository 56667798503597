import React from "react";
import { Helmet } from "react-helmet";
import '../styles/styles.css';

//Static
import info from '../images/index1.jpg'

//Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


const OmOs = () => {
    return (
        <>
            <Helmet>
                <title>Om os | Klippingeslagteren</title>
            </Helmet>
            <Navbar />
            <div className="mt-32 w-11/12 mx-auto pb-10 flex flex-col justify-center md:flex-row md:mt-44">
                <div className="w-full p-6 bg-gray-light rounded-2xl md:rounded-none md:rounded-l-2xl md:w-7/12">
                    <p>
                        Klippingeslagteren har siden år 2000 været drevet og ejet af Søren
                        Hansen. <br />
                        Søren har, siden han kom i lære i butikken i 1984, arbejdet inden
                        for slagterfaget. <br />
                        Kødet, der fremstilles varer af, og som sælges i butikken, kommer
                        fra mindre sjællandske slagterier og er af høj dansk kvalitet. Alle
                        varer er hjemmelavede, og mange af opskrifterne har Søren selv
                        komponeret eller videreført fra sin læremester. Butikken har eget
                        pølsemageri og røgeri, og derudover er Klippingeslagteren kendt for
                        sit pålæg, for hvilket folk kommer langvejs fra. <br />
                        <br />
                        Du vil i butikken på Tværvej 5, 4672 Klippinge opleve en rar
                        stemning og en god personlig betjening.
                        <br />
                        <br />
                        Vi modtager meget gerne forudbestillinger, hvis du fx har brug for
                        større mængder af kød eller andre varer.
                        <br />
                        <br />
                        Hvis du har nogle spørgsmål eller kommentarer, er du velkommen til
                        at kontakte os på tlf: <a href="tel:56578033"> 56578033</a> eller mail:&nbsp;
                        <a href="mailto:post@klippingeslagteren.dk" className="underline hover:text-red"
                        >post@klippingeslagteren.dk</a
                        > - også gerne på vores lukkedage.
                    </p>
                </div>
                <div className='w-full mt-4 md:mt-0 md:w-1/2'>
                    <img className='object-cover w-full h-full rounded-2xl md:rounded-none md:rounded-r-2xl'
                         src={info}
                         alt='About us' />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default OmOs