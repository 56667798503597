import React, { useState } from "react";
import logo from '../images/dkslagter-logo.png'

import menu from '../images/menu.svg'


const Navbar = () => {
    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false)

    const handleMenuClick = () => {
        if (!hamburgerMenuOpen) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        setHamburgerMenuOpen(!hamburgerMenuOpen)
    }
    return(
        <>
        <div className="flex fixed justify-between border-b top-0 left-0 px-10 py-5 w-full bg-white items-center z-10 md:border-none">
            <div className='flex flex-row space-x-6'>
                <div>
                    <p>
                        <a href='/'>
                            <img
                                src={logo}
                                alt="Klippingeslagterens logo"
                                width="88.5"
                                height="60" />
                        </a>
                    </p>
                </div>
                <a href='/' className='hidden items-center text-3xl md:flex'>
                    Klippingeslagteren
                </a>
            </div>
            <div className='hidden flex-col lg:flex'>
                <div className="info">
                     <p>
                         Tlf:
                         <a href="tel:56578033" > 56 57 80 33</a>
                     </p>
                </div>
                <div className="info">
                    <p>
                        Mail:
                        <a
                            href="mailto:post@klippingeslagteren.dk"
                            className="pl-2 underline hover:text-red"
                            title="Send en mail til Klippingeslagteren">
                            post@klippingeslagteren.dk
                        </a>
                    </p>
                </div>
            </div>
            <div className='flex flex-col md:hidden'>
                <button onClick={() => handleMenuClick() }>
                    <img className='h-14 rounded-md hover:shadow-xl hover:bg-gray-light duration-150'
                         src={menu}
                         alt='hamburger menu' />
                </button>
            </div>
        </div>
        <div className="hidden w-full justify-evenly fixed top-24 p-2 border-b border-b-black bg-red text-white z-10 md:flex">
            <a href='/' className='hover:transition-all hover:text-black hover:underline duration-150'> Forside </a>
            <a href='/Paalaeg' className='hover:transition-all hover:text-black hover:underline duration-150'> Pålæg </a>
            <a href='/Storkoeb' className='hover:transition-all hover:text-black hover:underline duration-150'> Storkøb </a>
            <a href='/Faerdigretter' className='hover:transition-all hover:text-black hover:underline duration-150'> Færdigretter </a>
            <a href='/Aabningstider' className='hover:transition-all hover:text-black hover:underline duration-150'> Åbningstider </a>
            <a href='/OmOs' className='hover:transition-all hover:text-black hover:underline duration-150'> Om os </a>
        </div>
        {hamburgerMenuOpen &&
        <div className='w-full flex flex-col mt-24 px-6 py-3 space-y-2 bg-red text-xl text-white
                        border-b border-b-black divide-y divide-black md:hidden
                        shadow shadow-2xl'>
            <a href='/' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Forside
            </a>
            <a href='/Paalaeg' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Pålæg
            </a>
            <a href='/Storkoeb' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Storkøb
            </a>
            <a href='/Faerdigretter' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Færdigretter
            </a>
            <a href='/Aabningstider' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Åbningstider
            </a>
            <a href='/OmOs' onClick={() => setHamburgerMenuOpen(false)}
                  className='pt-2 hover:transition-all hover:text-white hover:underline duration-150 '>
                Om os
            </a>
        </div>
        }
    </>
    )
}

export default Navbar;